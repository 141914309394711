<template>
<div>
  <team-accomplish :type="0" :diff="$route.query.diff" :examine="false"></team-accomplish>
</div>
</template>

<script>
import TeamAccomplish from "components/TeamAccomplish";
export default {
  name: "Unfinished",
  components: {
    TeamAccomplish
  }
}
</script>

<style scoped>

</style>
